import ConnectionIcon from '../../../images/ami/connection.svg';
import DegreeIcon from '../../../images/ami/degree.svg';
import LightBulbIcon from '../../../images/ami/lightbulb.svg';
import RobotIcon from '../../../images/ami/robot.svg';
import ScholarIcon from '../../../images/ami/scholar.svg';

export const CardIconData = [
  RobotIcon,
  ScholarIcon,
  ConnectionIcon,
  LightBulbIcon,
  DegreeIcon
];
