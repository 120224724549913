import BubblesAbout from '../../images/ami/bubble/about.svg';
import BubblesDetails from '../../images/ami/bubble/details.svg';
import BubblesDetailsBlue from '../../images/ami/bubble/details-blue.png';
import BubblesImportantInfo from '../../images/ami/bubble/important-info.svg';
import BubblesPartners from '../../images/ami/bubble/partner.svg';
import BubblesPartnersBlue from '../../images/ami/bubble/partner-2.svg';
import BubblesPartnersGradient from '../../images/ami/bubble/partner-3.svg';
import BubblesPromoted from '../../images/ami/bubble/bee.svg';
import BubblesPromotedBlue from '../../images/ami/bubble/bee-blue.svg';
import BubblesWorkshopGradient from '../../images/ami/bubble/workshop-gradient.svg';
import BubblesWorkshopGradient2 from '../../images/ami/bubble/workshop-gradient-2.svg';
import BubblesWorkshops from '../../images/ami/bubble/workshops.svg';
import BubblesWorkshopsBlue from '../../images/ami/bubble/workshop-purple.svg';
import HeroBg from "../../assets/hero-bg.png";
import PromoBg from '../../images/ami/bubble/promotion-bg.png'
import { respondTo } from "../../utils/breakpoints";
import styled from "styled-components";

export const OverTitle = styled.h3`
  font: ${(props) => props.theme.typography.headers.h2m};
  ${respondTo.m`
    font: ${(props) => props.theme.typography.headers.h2};
  `}
  font-weight: 500 !important;
  letter-spacing: -0.72px;
  margin: 0;
  color: ${(props) => props.theme.colors.uiWhite};
`;

export const HeroTitle = styled.h1`
  font: ${(props) => props.theme.typography.headers.h1m};
  ${respondTo.m`
    font: ${(props) => props.theme.typography.headers.h1};
  `}
  margin: 0;
  color: ${(props) => props.theme.colors.uiWhite};
`;

export const Lead = styled.p`
  font: ${(props) => props.theme.typography.headers.h3};
  margin: 0;
  color: ${(props) => props.theme.colors.academyBlue};
`

export const AmiContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  &.custom-w {
    width: 100%;
    ${respondTo.m`
      width: ${(props) => props.customWidth || '100%'};
    `}
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.gap-18 {
    ${respondTo.m`
      gap: 18px;
    `}
    }
  ${respondTo.m`
    flex-direction: column;
    gap: 48px;
    &.gap-24 {
      gap: 24px;
    }
  `}
`;

export const AmiHeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${respondTo.m`
    gap: 16px;
  `}
`;

export const AmiHeroContainer = styled.div`
  width: 100%;
  ${respondTo.m`
    width: 796px;
  `}
`

export const AmiHero = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const AmiSection = styled.section`
  padding-top: 0;
  &.details {
    background: url(${BubblesDetails}) no-repeat right 10%, url(${BubblesDetailsBlue}) no-repeat left 50%;
    background-color: #F9F8FC;
  }
  &.for-whom {
    background: url(${BubblesImportantInfo}) no-repeat right 10%;
  }
  &#hero {
    background-image: url(${HeroBg});
    background-size: cover;
    margin-top: 113px;
    padding: 62px 0px 0px 0px;
    ${respondTo.m`
      padding: 62px 0px 92px 0px;
    `}
  }
  &.what-is {
    background: url(${BubblesAbout}) no-repeat right 10%;
  }
  &.details {
  }
  &.promoted {
    background: linear-gradient(193deg, #4EBFEE 4.53%, #52368C 96.56%);
  }
  &.promoted-nest {
    width: 100%;
    height: 100%;
    background: url(${BubblesPromoted}) no-repeat right 70%, url(${BubblesPromotedBlue}) no-repeat left bottom;
    ${respondTo.m`
      background: url(${BubblesPromoted}) no-repeat right 20%, url(${BubblesPromotedBlue}) no-repeat left bottom; 
    `}
  }
  &.workshops {
    background: url(${BubblesWorkshopsBlue}) no-repeat right 40%, url(${BubblesWorkshops}) no-repeat left 0%, url(${BubblesWorkshopGradient2}) no-repeat right 90%;
    ${respondTo.m`
      background: url(${BubblesWorkshops}) no-repeat left 30%, url(${BubblesWorkshopsBlue}) no-repeat right 10%, url(${BubblesWorkshopGradient}) no-repeat 5% 80%, url(${BubblesWorkshopGradient2}) no-repeat right 90%;
    `}
  }
  &.partners {
    background: url(${BubblesPartnersBlue}) no-repeat right 20%, url(${BubblesPartnersGradient}) no-repeat right 70%;
    ${respondTo.m`
      background: url(${BubblesPartnersBlue}) no-repeat right 5%, url(${BubblesPartners}) no-repeat left 40%, url(${BubblesPartnersGradient}) no-repeat right 95%;
    `}
  }
  &.about {
    background-color: ${(props) => props.theme.colors.academyBlue};
    background: linear-gradient(180deg, #4FBCEC 0%, #524194 100%), url(${PromoBg}) no-repeat;
    position: relative;
    z-index: 0;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background: linear-gradient(193deg, #060E15 4.53%, #52368C 96.56%);
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
      background: linear-gradient(180deg, #4FBCEC 0%, #524194 100%);
      mix-blend-mode: hard-light;
      z-index: 1;
    }
    > * div, iframe {
      z-index: 3;
    }
  }
`

export const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${respondTo.m`
  `}
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
  &.mobile-wrap {
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 24px;
    ${respondTo.m`
      padding-top: 0;
      flex-direction: row;
      gap: 24px;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      gap: 0px;
    `}
  }
  ${respondTo.m`
    flex-direction: row;
  `}
  &.align-start {
    align-items: flex-start;
  }
`

export const Column = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 16px 0px;
  flex-direction: column;
  &.p-0 {
    padding: 0;
  }
  &.gap-4 {
    gap: 4px;
  }
  &.gap-0 {
    gap: 0;
  }
`

export const AmiSectionHeader = styled.h2`
  font: ${(props) => props.theme.typography.headers.h2m} !important;
  ${respondTo.m`
    font: ${(props) => props.theme.typography.headers.h2} !important;
  `}
  margin: 0;
  text-transform: none;
  color: ${(props) => props.theme.colors.uiBlack};
  &.purple {
    color: ${(props) => props.theme.colors.uiPurple};
  }
  &.white {
    color: ${(props) => props.theme.colors.uiWhite};
  }
  & > strong {
    font-weight: 600;
    color: ${(props) => props.theme.colors.uiPurple};
  }
  &.text-left {
    text-align: left !important;
  }
`

export const Text = styled.p`
  font: ${(props) => props.theme.typography.body.s};
  margin: 0;
  color: ${(props) => props.theme.colors.uiBlack};
  font-weight: 400;
  &.purple {
    color: ${(props) => props.theme.colors.uiPurple};
  }
  &.bold {
    font-weight: 600;
  }
  &.montserrat {
    font-family: 'Montserrat', sans-serif;
  }
  &.s-18 {
    font-size: 18px;
  }
  &.white {
    color: ${(props) => props.theme.colors.uiWhite};
  }
  &.s-24 {
    font-size: 18px;
    ${respondTo.m`
      font-size: 24px;
    `}
  }
  &.weight-300 {
    font-weight: 300 !important;
  }
`

export const AmiSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 48px;
  ${respondTo.m`
    flex-direction: row;
    gap: 0px;
  `}
  &.column {
    flex-direction: column;
    gap: 33px;
  }
  &.p-60 {
    padding: 32px 0px;
    ${respondTo.m`
      padding: 60px 0px;
    `}
  }
  &.p-80 {
    padding: 48px 0px;
    ${respondTo.m`
      padding: 80px 0px;
    `}
  }
  &.gap-69 {
    gap: 69px;
  }
`

export const AmiCircleBorderedImage = styled.img`
  aspect-ratio: 1/1;
  height: auto;
  width: 100%;
  ${respondTo.m`
    width: 479px;
  `}
  &.promo-2 {
    width: 100%;
    height: auto;
    ${respondTo.m`
    width: 557.5px;
    height: 508.5px;  `}
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
`;
