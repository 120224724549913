import {
  AMIBootcamp,
  AMIBootcampEN,
  AMIWorkshops,
  AMIWorkshopsEN
} from '../../data/agenda';
import {
  AmiSection,
  AmiSectionContainer,
  AmiSectionHeader,
  Text
} from '../../styled.components';

import CustomAgendaComponent from '../CustomAgendaComponent';
import React from 'react';
import { SectionWrapper } from '../../../../components/global/flexbox';
import { Speaker } from '../../../../templates/workshops/components/speakers';
import { SpeakerTitles } from '../../data/speaker-titles';
import { respondTo } from '../../../../utils/breakpoints';
import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function AmiAgenda(props) {
  const { t, i18n } = useTranslation('ami');
  const [selectedAgenda, setSelectedAgenda] = useState(
    i18n.language === 'en' ? AMIWorkshopsEN : AMIWorkshops
  );
  const speakers = Object.values(props);

  const handleAgendaChange = (type) => {
    if (i18n.language === 'en') {
      setSelectedAgenda(type === AMIWorkshops ? AMIWorkshopsEN : AMIBootcampEN);
    } else {
      setSelectedAgenda(type);
    }
  };

  return (
    <>
      <AmiSection>
        <SectionWrapper>
          <AmiSectionContainer className="column p-60">
            <AmiSectionHeader>{t('agendaSection.title')}</AmiSectionHeader>
            <AgendaBar>
              {[AMIWorkshops, AMIBootcamp].map((agenda, index) => (
                <AgendaButton
                  key={index}
                  selected={
                    selectedAgenda === agenda ||
                    selectedAgenda ===
                      (agenda === AMIWorkshops ? AMIWorkshopsEN : AMIBootcampEN)
                  }
                  onClick={() => handleAgendaChange(agenda)}>
                  {t(`agendaSection.${index}`)}
                </AgendaButton>
              ))}
            </AgendaBar>
            <Agenda>
              {selectedAgenda.map((workshop, index) => (
                <CustomAgendaComponent
                  key={index}
                  isDay={
                    selectedAgenda === AMIWorkshops ||
                    selectedAgenda === AMIWorkshopsEN
                  }
                  {...workshop}
                />
              ))}
            </Agenda>
          </AmiSectionContainer>
        </SectionWrapper>
      </AmiSection>
      <AmiSection className="workshops">
        <SectionWrapper>
          <AmiSectionContainer className="column p-60">
            <HeaderContent>
              <AmiSectionHeader className="purple">
                {t('speakersSection.title')}
              </AmiSectionHeader>
              <Text className="text-center">{t('speakersSection.text')}</Text>
            </HeaderContent>
            <SpeakersWrapper>
              {speakers.map((speaker, index) => (
                <SpeakerItem key={speaker.name}>
                  <Speaker
                    {...speaker}
                    noDescription
                    title={SpeakerTitles[index]}
                  />
                </SpeakerItem>
              ))}
            </SpeakersWrapper>
          </AmiSectionContainer>
        </SectionWrapper>
      </AmiSection>
    </>
  );
}

const Agenda = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
`;

const SpeakersWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px !important;
  width: 100%;
  flex-direction: column;
  ${respondTo.m`
    flex-wrap: wrap;
    flex-direction: row;
  `}
`;
const SpeakerItem = styled.div`
  width: 100%;
  ${respondTo.m`
    width: 182px;
  `}
`;

export const HeaderContent = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  ${respondTo.m`
  width: 680px;

  `}
  &.flex-start {
    justify-content: flex-start;
    justify-self: flex-start;
    align-self: flex-start;
    text-align: left !important;
    > h2 {
      text-align: left !important;
    }
  }
`;

const AgendaTypes = {
  WORKSHOP: 'workshop',
  BOOTCAMP: 'bootcamp'
};

const AgendaBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const AgendaButtonWrapper = styled.div`
  display: flex;
  border-radius: 8px;
`;

const AgendaButton = styled.button`
  padding: 10px 20px;
  font-size: 18px;
  font-family: 'Montserrat';
  min-height: 54px;
  max-height: 70px;
  border: none;
  cursor: pointer;
  &:nth-of-type(1) {
    border-radius: 8px 0 0 8px;
  }
  &:nth-of-type(2) {
    border-radius: 0 8px 8px 0;
  }
  background-color: ${({ selected }) => (selected ? '#52368C' : '#ffffff')};
  color: ${({ selected }) => (selected ? '#ffffff' : '#52368C')};
  border-radius: 4px;
  &:hover {
    background-color: ${({ selected }) => (selected ? '#52368C' : '#ffffff')};
  }
`;
