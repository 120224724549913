import AkademiaMlodegoInnowatoraPage from '../views/akademia-mlodego-innowatora';
import { graphql } from 'gatsby';

export default AkademiaMlodegoInnowatoraPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    amiData: contentfulStronaEventowa(contentful_id: {eq: "5UeDkKJWk9ZaItt9u99vHX"}) {
      gallery {
        galeria {
          gatsbyImageData
        }
      }
      speakers {
        name
        description {
          description
        }
        linkedin
        photo {
          title
          file {
            url
          }
        }
      }
    }
  }
`;
