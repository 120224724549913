export const SpeakerTitles = [
  'BPS Consultant w Deloitte',
  'Założyciel Fundacji Prawo Zysku',
  'Założyciel Fundacji Prawo Zysku, Aplikant radcowski w Okręgowej Izbie Radców Prawnych w Warszawie',
  'Co-owner Alfa Lingua',
  'Dyrektor ds. AI w TVN Warner Bros. Discovery',
  'Członek Zarządu QED Solutions',
  'Prezes i Założyciel Econverse',
  'Co-founder & ex-CCO Ad Personam',
  'Wiceprezes i Założyciel Econverse',
  'DAGskill Co-founder, ex-EYnnovation Leader',
  'DAGskill Co-founder, CTO w Wellbee',
  'Student Minerva University',
  'Co-founder AI Consensus'
]