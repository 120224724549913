import About from './components/sections/About';
import AmiAgenda from './components/sections/AmiAgenda';
import Details from './components/sections/Details';
import Gallery from './components/sections/Gallery';
import Hero from './components/sections/Hero';
import Layout from '../../components/layout/layout';
import Promoted from './components/sections/Promoted';
import React from 'react';
import Seo from '../../components/seo';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../themes/default';
import { useTranslation } from 'react-i18next';

export default function AkademiaMlodegoInnowatoraPage({ data }) {
  const { t, i18n } = useTranslation('ami');
  const currentLanguage = i18n.language;
  const { locales, amiData } = data;
  const galleryArray = Object.values(amiData.gallery[0].galeria);
  console.log(galleryArray);
  return (
    <ThemeProvider theme={theme}>
      <Layout withHeader={false}>
        <Seo title={t('meta.title')} description={t('meta.description')} />
        <Hero />
        <About />
        <Details />
        <Promoted />
        <AmiAgenda {...amiData.speakers} />
        <Gallery {...galleryArray} />
      </Layout>
    </ThemeProvider>
  );
}
