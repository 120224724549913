import { NormalText } from '../../../../components/global/utils';
import { Row } from '../../../../components/global/utils';
import styled from 'styled-components';

export const SpeakerImg = styled.img`
  width: 120px;
  border: 7px solid #2b95b7;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  &.m-1 {
    margin: 1rem;
  }
`;
export const SpeakerTitle = styled(NormalText)`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #0f5575;
  margin-bottom: 1rem;
  &.margin-0 {
    margin: 0;
  }
`;
export const SpeakerDescription = styled(NormalText)`
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #0f5575;
  margin-bottom: 1rem;
  max-width: 280px;
  &.margin-0 {
    margin: 0;
  }
`;
export const LinkedinRow = styled(Row)`
  color: #5fa5c5;
  font-weight: 500;
  font-size: 14px;
  a {
    text-decoration: none;
    color: #5fa5c5;
  }
  i {
    margin-left: 0;
    margin-right: 8px;
  }
`;

export const SpeakerTitleBold = styled(SpeakerTitle)`
  font-weight: 600;
  color: #5FA5C5;
  font-family: 'Raleway';
  font-size: 14px;
`;