import {
  AmiCircleBorderedImage,
  AmiContainer,
  AmiSection,
  AmiSectionContainer,
  AmiSectionHeader,
  Column,
  HeaderWrapper,
  Row,
  Text
} from '../../styled.components';
import { Trans, useTranslation } from 'react-i18next';

import { CardIconData } from '../../data/card';
import ClickMeetingLogo from '../../../../images/ami/clickmeeting.png';
import ForumLogo from '../../../../images/ami/forum-logo.png';
import LocationIcon from '../../../../images/ami/location-pin.svg';
import PeopleIcon from '../../../../images/ami/people-icon.svg';
import React from 'react';
import { SectionWrapper } from '../../../../components/global/flexbox';
import { respondTo } from '../../../../utils/breakpoints';
import styled from 'styled-components';

export default function Details() {
  const { t } = useTranslation('ami');
  const cards = t('benefitsSection.items', { returnObjects: true });
  return (
    <>
      <AmiSection className="details">
        <SectionWrapper>
          <AmiSectionContainer className="p-80 column">
            <AmiSectionHeader className="">
              <Trans i18nKey="benefitsSection.title" />
            </AmiSectionHeader>
            <CardGrid>
              {cards.map((card, index) => (
                <AMICard key={index}>
                  <CardIcon>
                    <img
                      src={CardIconData[index]}
                      alt="icon"
                      style={{ width: '36px', height: '36px' }}
                    />
                  </CardIcon>
                  <CardContent>
                    <CardHeader className="purple">{card.title}</CardHeader>
                    <Text className="s-16">{card.text}</Text>
                  </CardContent>
                </AMICard>
              ))}
            </CardGrid>
          </AmiSectionContainer>
        </SectionWrapper>
      </AmiSection>
      <AmiSection>
        <SectionWrapper>
          <AmiSectionContainer className="p-80 column">
            <HeaderWrapper>
              <PreTitle>{t('eventDetails.where-when')}</PreTitle>
              <AmiSectionHeader className="text-left">
                {t('eventDetails.title')}
              </AmiSectionHeader>
            </HeaderWrapper>
            <CardGrid>
              <AMICard className="xxl">
                <CardIcon>
                  <img
                    src={LocationIcon}
                    alt="icon"
                    style={{ width: '36px', height: '36px' }}
                  />
                </CardIcon>
                <CardContent className="gap-16">
                  <CardHeader>
                    {t('eventDetails.location.where-and-when')}
                  </CardHeader>
                  <Column className="p-0">
                    <Row className="align-start">
                      <Column className="p-0 gap-4">
                        <Text className="purple bold montserrat">
                          23.09 - 7.10.2024
                        </Text>
                        <Text>{t('eventDetails.location.online.text')}</Text>
                      </Column>
                      <Column className="p-0 gap-4">
                        <Text className="purple bold montserrat">
                          19.10 2024
                        </Text>
                        <Text>
                          {t('eventDetails.location.inPerson.address')}
                        </Text>
                      </Column>
                    </Row>
                    <Row>
                      <img
                        height={70}
                        src={ClickMeetingLogo}
                        alt="ClickMeeting"
                      />
                      <img height={70} src={ForumLogo} alt="Forum" />
                    </Row>
                  </Column>
                </CardContent>
              </AMICard>
              <AMICard className="xl">
                <CardIcon>
                  <img
                    src={PeopleIcon}
                    alt="icon"
                    style={{ width: '36px', height: '36px' }}
                  />
                </CardIcon>
                <CardContent className="gap-16">
                  <CardHeader>
                    {t('eventDetails.targetAudience.title')}
                  </CardHeader>
                  <Text>{t('eventDetails.targetAudience.text')}</Text>
                </CardContent>
              </AMICard>
            </CardGrid>
          </AmiSectionContainer>
        </SectionWrapper>
      </AmiSection>
    </>
  );
}

const CardGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CardHeader = styled.div`
  font: ${(props) => props.theme.typography.headers.h3};
  font-weight: 600;
  color: ${(props) => props.theme.colors.uiPurple};
  margin: 0;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  &.gap-16 {
    gap: 16px;
  }
`;

const CardIcon = styled.div`
  display: flex;
  width: 80px !important;
  height: 80px !important;
  aspect-ratio: 1/1 !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--Akademia-light-bg, #f9f8fc);
`;

const AMICard = styled.div`
  padding: 16px 20px;
  border-radius: 32px;
  border: 1px solid #e2daf1;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(18, 102, 140, 0.15);
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  ${respondTo.m`
      width: 340px;
  height: 232px;
  `}
  &.xxl {
    width: 100%;
    ${respondTo.m`
          width: 632px;
    height: 253px;
    padding: 16px 24px;
    flex-direction: row;

    `}
    padding: 12px 20px;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }
  &.xl {
    width: 100%;
    ${respondTo.m`
    width: 431px;
        height: 253px;
    flex-direction: row;

    `}
    gap: 16px;
    flex-direction: column;
  }
`;

const PreTitle = styled.div`
  font: ${(props) => props.theme.typography.body.h3};
  font-weight: 500;
  color: ${(props) => props.theme.colors.uiPurple};
  margin: 0;
`;
