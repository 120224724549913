import {
  ComponentWrapper,
  GalleryInformationContainer,
  GalleryItemImage,
  GalleryItemWrapper,
  GallerySubtitle,
  GalleryTitle,
  GalleryWrapper
} from './styled.components';
import React, { useState } from 'react';

import { StyledBtn } from '../global/buttons/styled.components';
import { isWindow } from '../global/utils';
import { useTranslation } from 'react-i18next';

export function GalleryComponent(props) {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const gallery = props.gallery;
  const isNotGatsby = props.isNotGatsby || true;

  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreVisibility, setShowMoreVisibility] = useState(true);
  let pageLenght = 6;
  const startIndex = 0;
  const endIndex = startIndex + pageLenght * currentPage;
  const totalPages = Math.ceil(gallery.length / pageLenght);
  let paginatedItems = gallery.slice(startIndex, endIndex);

  function loadMoreItems() {
    switch (true) {
      case currentPage < totalPages:
        setShowMoreVisibility(true);
        setCurrentPage((currentPage) => currentPage + 1);
        break;
      case currentPage === totalPages:
        setShowMoreVisibility(false);
      default:
        break;
    }
  }
  function goToFirstPage() {
    setCurrentPage(1);
    setShowMoreVisibility(true);
  }
  function openImageInNewCard(src) {
    if (isWindow) {
      window.open(src, '_blank');
    }
  }

  return (
    <ComponentWrapper>
      <GalleryWrapper>
        {paginatedItems.map((galleryItem, index) => (
          <GalleryItemWrapper key={index}>
            <GalleryItem
              onClick={() =>
                openImageInNewCard(
                  galleryItem.gatsbyImageData.images.fallback.src
                )
              }
              item={galleryItem.gatsbyImageData}
            />
            <GalleryInformationContainer>
              {currentLang === 'pl' ? (
                <>
                  <GalleryTitle>{galleryItem?.pl?.title}</GalleryTitle>
                  <GallerySubtitle>{galleryItem?.pl?.subtitle}</GallerySubtitle>
                </>
              ) : (
                <>
                  <GalleryTitle>{galleryItem?.en?.title}</GalleryTitle>
                  <GallerySubtitle>{galleryItem?.en?.subtitle}</GallerySubtitle>
                </>
              )}
            </GalleryInformationContainer>
          </GalleryItemWrapper>
        ))}
      </GalleryWrapper>
      {!showMoreVisibility ? (
        <StyledBtn className="purple" onClick={() => goToFirstPage()}>
          {t('hide')}
        </StyledBtn>
      ) : (
        <StyledBtn className="purple" onClick={() => loadMoreItems()}>
          {t('show_more')}
        </StyledBtn>
      )}
    </ComponentWrapper>
  );
}

const GalleryItem = ({ item, onClick }) => {
  return (
    <GalleryItemWrapper onClick={onClick}>
      <picture>
        {item.images.sources.map((source, index) => (
          <source
            key={index}
            srcSet={source.srcSet}
            sizes={source.sizes}
            type={source.type}
          />
        ))}
        <GalleryItemImage
          src={item.images.fallback.src}
          srcSet={item.images.fallback.srcSet}
          sizes={item.images.fallback.sizes}
          alt={item.alt}
        />
      </picture>
    </GalleryItemWrapper>
  );
};
