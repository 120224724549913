const AMIWorkshops = [
  {
    name: 'Wprowadzenie do sztucznej inteligencji',
    speakers: 'Piotr Biczyk',
    startTime: '2023-09-23T11:15+02:00',
    panelLength: '45'
  },
  {
    name: 'Jak wykorzystać AI we własnych projektach, personalizacji własnej ścieżki kariery i rozwoju osobistym?',
    speakers: 'Jan Bartkowiak',
    startTime: '2023-09-26T11:00+02:00',
    panelLength: '45'
  },
  {
    name: "AI w E-Commerce i jak wykorzystać AI w tworzeniu nowych produktów?",
    speakers: "Krystian Nowak & Leo Wu ",
    startTime: '2023-09-30T11:00+02:00',
    panelLength: '45'
  },
  {
    name: "Jak wykorzystać AI w rekrutacji?",
    speakers: "Michał Piętka & Radosław Dembkowski ",
    startTime: '2023-10-03T11:00+02:00',
    panelLength: '45'
  },
  {
    name: "Czy AI zabija twórczość? AI i branża kreatywna",
    speakers: "Radosław Kita",
    startTime: '2023-10-04T11:00+02:00',
    panelLength: '45'
  },
  {
    name: "AI w Sales",
    speakers: "Hubert Pyskło & Jakub Kalbarczyk",
    startTime: '2023-10-07T12:00+02:00',
    panelLength: '45'
  }
]

const AMIBootcamp = [
  {
    startTime: '2023-09-23T09:30+02:00',
    panelLength: '30',
    name: 'Rejestracja uczestników',
    icon: 'register'
  },
  {
    startTime: '2023-09-23T10:00+02:00',
    panelLength: '15',
    name: 'Rozpoczęcie Bootcampu Akademii Młodego Innowatora',
    icon: 'register'
  },
  {
    startTime: '2023-09-23T10:15+02:00',
    panelLength: '45',
    name: 'Podstawy księgowości w firmie',
    speakers: 'Aleksandra Kulon'
  },
  {
    startTime: '2023-09-23T11:00+02:00',
    panelLength: '15',
    name: 'Przerwa'
  },
  {
    startTime: '2023-09-23T11:15+02:00',
    panelLength: '45',
    name: 'Sztuczna inteligencja, a podstawy prawa w firmie',
    speakers: 'Oskar Litwin & Jakub Maciejewski'
  },
  {
    startTime: '2023-09-23T12:00+02:00',
    panelLength: '15',
    name: 'Przerwa'
  },
  {
    startTime: '2023-09-23T12:15+02:00',
    panelLength: '120',
    name: 'Projektowanie firmy z wykorzystaniem AI - cz. 1',
    speakers: 'Dawid Gościk'
  },
  {
    startTime: '2023-09-23T14:15+02:00',
    panelLength: '30',
    name: 'Przerwa obiadowa'
  },
  {
    startTime: '2023-09-23T14:45+02:00',
    panelLength: '120',
    name: 'Projektowanie firmy z wykorzystaniem AI - cz. 2',
    speakers: 'Dawid Gościk'
  },
  {
    startTime: '2023-09-23T16:45+02:00',
    panelLength: '45',
    name: 'Networking'
  },
  {
    startTime: '2023-09-23T17:30+02:00',
    panelLength: '30',
    name: 'Podsumowanie Akademii'
  }
]

const AMIWorkshopsEN = [
  {
    name: 'Introduction to Artificial Intelligence',
    speakers: 'Piotr Biczyk',
    startTime: '2023-09-23T11:15+02:00',
    panelLength: '45'
  },
  {
    name: 'How to Use AI in Your Own Projects, Personalize Your Career Path, and Personal Development?',
    speakers: 'Jan Bartkowiak',
    startTime: '2023-09-26T11:00+02:00',
    panelLength: '45'
  },
  {
    name: 'AI in E-Commerce and How to Use AI in Creating New Products?',
    speakers: 'Krystian Nowak & Leo Wu',
    startTime: '2023-09-30T11:00+02:00',
    panelLength: '45'
  },
  {
    name: 'How to Use AI in Recruitment?',
    speakers: 'Michał Piętka & Radosław Dembkowski',
    startTime: '2023-10-03T11:00+02:00',
    panelLength: '45'
  },
  {
    name: 'Does AI Kill Creativity? AI and the Creative Industry',
    speakers: 'Radosław Kita',
    startTime: '2023-10-04T11:00+02:00',
    panelLength: '45'
  },
  {
    name: 'AI in Sales',
    speakers: 'Hubert Pyskło & Jakub Kalbarczyk',
    startTime: '2023-10-07T12:00+02:00',
    panelLength: '45'
  }
]

const AMIBootcampEN = [
  {
    startTime: '2023-09-23T09:30+02:00',
    panelLength: '30',
    name: 'Participant Registration',
    icon: 'register'
  },
  {
    startTime: '2023-09-23T10:00+02:00',
    panelLength: '15',
    name: 'Bootcamp Opening of the Young Innovator Academy',
    icon: 'register'
  },
  {
    startTime: '2023-09-23T10:15+02:00',
    panelLength: '45',
    name: 'Basics of Accounting in a Company',
    speakers: 'Aleksandra Kulon'
  },
  {
    startTime: '2023-09-23T11:00+02:00',
    panelLength: '15',
    name: 'Break'
  },
  {
    startTime: '2023-09-23T11:15+02:00',
    panelLength: '45',
    name: 'Artificial Intelligence and Legal Basics in a Company',
    speakers: 'Oskar Litwin & Jakub Maciejewski'
  },
  {
    startTime: '2023-09-23T12:00+02:00',
    panelLength: '15',
    name: 'Break'
  },
  {
    startTime: '2023-09-23T12:15+02:00',
    panelLength: '120',
    name: 'Company Design Using AI - Part 1',
    speakers: 'Dawid Gościk'
  },
  {
    startTime: '2023-09-23T14:15+02:00',
    panelLength: '30',
    name: 'Lunch Break'
  },
  {
    startTime: '2023-09-23T14:45+02:00',
    panelLength: '120',
    name: 'Company Design Using AI - Part 2',
    speakers: 'Dawid Gościk'
  },
  {
    startTime: '2023-09-23T16:45+02:00',
    panelLength: '45',
    name: 'Networking'
  },
  {
    startTime: '2023-09-23T17:30+02:00',
    panelLength: '30',
    name: 'Academy Summary'
  }
]


export { AMIWorkshops, AMIBootcamp, AMIWorkshopsEN, AMIBootcampEN };

