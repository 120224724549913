import React from 'react';
import moment from 'moment';
import { respondTo } from '../../../utils/breakpoints';
import styled from 'styled-components';

export default function CustomAgendaComponent(props) {
  const time = props.isDay
    ? moment(props.startTime).format('DD.MM')
    : moment(props.startTime).format('HH:mm');
  return (
    <AgendaBar>
      <AgendaTime>{time}</AgendaTime>
      <AgendaContentWrapper>
        <AgendaContent>
          <AgendaTitle>{props.name}</AgendaTitle>
          <AgendaSpeakers>{props.speakers}</AgendaSpeakers>
        </AgendaContent>
        {!props.isDay && (
          <AgendaDetails>
            {props.icon && (
              <AgendaIconWrapper>
                <AgendaIcon src={`/assets/icons/${props.icon}.svg`} />
              </AgendaIconWrapper>
            )}
            <Pill>
              <AgendaIcon
                src={'/assets/icons/clock.svg'}
                style={{ width: '16px', height: '16px' }}
              />
              {String(props.panelLength + 'min')}
            </Pill>
          </AgendaDetails>
        )}
      </AgendaContentWrapper>
    </AgendaBar>
  );
}

const AgendaBar = styled.div`
  border-radius: 8px;
  background-color: #fff;
  max-width: 1180px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: flex-start;
  box-shadow: 2px 2px 20px 0px rgba(82, 54, 140, 0.15);
`;

const AgendaTime = styled.div`
  display: flex;
  width: 80px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  align-self: stretch;
  background: #f9f8fc;
  color: ${(props) => props.theme.colors.uiPurple};
`;

const AgendaContent = styled.div`
  box-sizing: border-box;
  padding: 10px 16px;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const AgendaContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  ${respondTo.m`
    flex-direction: row;
  align-items: center;

  `}
`;

const AgendaDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 0px 16px 16px 16px;
  ${respondTo.m`
    padding: 16px;
  `}
`;

const AgendaTitle = styled.h3`
  color: var(--Akademia-Dark, #060e15);
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  padding: 0px;
  margin: 0px;
`;

const AgendaSpeakers = styled.p`
  color: ${(props) => props.theme.colors.uiPurple};
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;

const Pill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 4px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background-color: #f9f8fc;
  color: ${(props) => props.theme.colors.uiPurple};
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
`;

export const AgendaIcon = styled.img`
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
  background-position-x: left;
  color: ${(props) => props.theme.colors.uiPurple};
  stroke: ${(props) => props.theme.colors.uiPurple};

  > svg {
    stroke: ${(props) => props.theme.colors.uiPurple};
  }
`;

export const AgendaIconWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.uiPurple};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: ${(props) => props.theme.colors.uiPurple};
`;
