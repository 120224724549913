import { respondTo } from '../../utils/breakpoints';
import styled from 'styled-components';

export const GalleryInformationContainer = styled.div`
  display: none;
  position: absolute;
  bottom: 100px;
  left: 25px;
`
export const GalleryTitle = styled.div`
  color: white;
  font-size: 24px;
`
export const GallerySubtitle = styled.div`
  color: white;
  font-size: 18px;
`

export const GalleryItemWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  ${respondTo.m`
      width: 380px;
  height: 380px;
  `}
  &:hover {
    > ${GalleryInformationContainer} {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 10px;
    }
    &:before {
      transition: all 200ms ease-in;
      content: '';
      border-radius: 10px;      
      color: white;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background: black;
      opacity: 0.5;
    }
  }
`;

export const GalleryItemImage = styled.img`
  width: 100%;
  border-radius: 10px;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
`;

export const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${respondTo.m`
    justify-content: space-around;
  `}
  gap: 20px;
  padding-top: 20px;
`;

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`
