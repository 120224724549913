import {
  AmiCircleBorderedImage,
  AmiContainer,
  AmiSection,
  AmiSectionContainer,
  AmiSectionHeader,
  Text
} from '../../styled.components';
import {
  PageSection,
  SectionWrapper
} from '../../../../components/global/flexbox';
import { Trans, useTranslation } from 'react-i18next';

import GrafikaPromo from '../../../../images/ami/grafika-promo.png';
import React from 'react';

export default function Promoted() {
  const { t } = useTranslation('ami');
  return (
    <AmiSection className="promoted">
      <AmiSection className="promoted-nest">
        <SectionWrapper>
          <AmiSectionContainer className="p-60">
            <AmiContainer
              customWidth="580px"
              className="gap-24 custom-w justify-start">
              <AmiSectionHeader className="white text-left">
                {t('highlightSection.title')}
              </AmiSectionHeader>
              <Text className="white s-24 montserrat weight-300">
                <Trans
                  i18nKey="highlightSection.text"
                  components={{
                    beehoneylink: (
                      <a
                        href="https://beehoney.pl"
                        rel="noreferrer"
                        style={{
                          color: '#6C63FF',
                          textDecoration: 'underline'
                        }}
                      />
                    )
                  }}
                />
              </Text>
            </AmiContainer>
            <AmiCircleBorderedImage
              className="promo-2"
              src={GrafikaPromo}
              alt="Promo"
            />
          </AmiSectionContainer>
        </SectionWrapper>
      </AmiSection>
    </AmiSection>
  );
}
